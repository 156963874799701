<!-- 人才政策 -->
<template>
  <div>
    <!-- 通用标题组件 -->
    <!--        <headtitle Otitle="人才政策"/>-->
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right div40">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>申报管理</el-breadcrumb-item>
        <el-breadcrumb-item>申报设置</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 功能按钮 -->
      <div class="tittool" style="height: 40px; text-align: left;">
        <el-button
          type="primary"
          v-if="dialogVisible"
          plain
          size="mini"
          @click="dialogVisible = !dialogVisible"
          >添加</el-button
        >
        <el-button
          type="primary"
          v-else
          plain
          size="mini"
          @click="dialogVisible = !dialogVisible"
          >切换列表</el-button
        >
      </div>
      <!-- users.slice((page-1)*pageSize,page*pageSize)-->
      <div class="searchtool fr">
        <el-button
          type="success"
          v-show="status === 0"
          plain
          size="mini"
          @click="modifyForm(1)"
          >申请开放</el-button
        >
        <el-button
          type="warning"
          v-show="status === 1"
          plain
          size="mini"
          @click="modifyForm(0)"
          >关闭申请</el-button
        >
      </div>

      <div v-if="dialogVisible">
        <el-table
          v-model="rowForm"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          v-loading="loading"
        >
          <el-table-column
            type="selection"
            width="55"
            :disabled="disabled"
          ></el-table-column>

          <el-table-column label="标题" prop="title" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status !== 0">
                <el-link
                  type="danger"
                  v-if="deftime(scope.row.expireTime) === 0"
                  style="font-weight: bold;font-size: 25px;"
                  title="一日内截止 请及时关闭"
                  >!</el-link
                >
                <el-link
                  type="warning"
                  v-else-if="
                    deftime(scope.row.expireTime) <= 2 &&
                      deftime(scope.row.expireTime) > 0
                  "
                  style="font-weight: bold;font-size: 25px;"
                  title="两日内截止 请及时关闭"
                  >!</el-link
                >
              </span>
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column label="开始时间" width="120">
            <template slot-scope="scope">{{
              scope.row.startTime | formatDate
            }}</template>
          </el-table-column>

          <el-table-column label="截至时间" width="120">
            <template slot-scope="scope">{{
              scope.row.expireTime | formatDate
            }}</template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="类型"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.type === 'talent'"
                >个人政策</el-tag
              >
              <el-tag v-else-if="scope.row.type === 'enterprise'"
                >单位政策</el-tag
              >
              <el-tag type="warning" v-else>类型有误</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="danger" v-if="deftime(scope.row.expireTime) < 0"
                ><span
                  v-text="scope.row.status === 1 ? '过期待关闭' : '已过期'"
                ></span
              ></el-tag>
              <el-tag type="success" v-else-if="scope.row.status === 1"
                >开启</el-tag
              >
              <el-tag type="danger" v-else>关闭</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                plain
                size="mini"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          @current-change="requestPage"
          id="pagination"
          :current-page="current"
        >
        </el-pagination>
        <span style="float: left;margin: -40px 0 0 0;"
          >共计{{ total }}条数据</span
        >
      </div>
      <edit v-else @zifu="hehe" />
    </el-card>

    <el-dialog
      v-model="createDialog"
      :visible.sync="createDialog"
      width="55%"
      id="form"
    >
      <div>
        <el-header><h3>编辑政策</h3></el-header>
        <el-form ref="ruleForm" :model="ruleForm" label-width="80px">
          <el-form-item label="文章标题">
            <el-input
              v-model="ruleForm.title"
              placement="请输入文章标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="文章内容">
            <textarea
              v-model="ruleForm.content"
              placement="请输入文章内容"
              :rows="10"
              style="width: 100%;font-size: 16px;border: #e0e0e0 1px solid"
            ></textarea>
          </el-form-item>
          <el-form-item label="开始时间" style="text-align: left;">
            <el-date-picker
              v-model="ruleForm.startTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" style="text-align: left;">
            <el-date-picker
              v-model="ruleForm.expireTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="创建时间" style="text-align: left;">
            <span
              style=" border: 1px #e4e4e4 solid;width: 200px; height: 40px;border-radius: 5px;padding: 0 0 0 15px;display: block;"
              >{{ ruleForm.createTime | formatDate }}</span
            >
          </el-form-item>
        </el-form>
        <div id="div-btn">
          <el-button plain @click="createDialog = !createDialog"
            >取消</el-button
          >
          <el-button type="primary" @click="requestEdit">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
import edit from "@/components/ControlPanel/edit";

export default {
  components: {
    //headtitle,
    edit,
  },
  data() {
    return {
      loading: false,
      disabled: true,
      status: 3,
      createDialog: false,
      ldbj: false,
      dialogVisible: true,
      page: 1,
      current: 1,
      pages: 0,
      pageSize: 10,
      total: 0,
      tableData: [],
      multipleSelection: [],
      editForm: {},
      ruleForm: {
        title: "",
        expireTime: "",
        createTime: "",
        startTime: "",
        id: "",
      },
      rowForm: {
        title: "",
        createTime: "",
        releaseTime: "",
        status: 0,
        type: "",
        content: "",
      },
    };
  },
  created() {
    if (this.$route.query.page) {
      this.current = Number(this.$route.query.page);
    }
    this.getDate(); //初始化 数据
  },
  methods: {
    hehe: function(data, data2) {
      this.dialogVisible = true;
      this.current = 1;
      this.getDate();
    },
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    //更新
    requestEdit() {
      if (this.ruleForm.title.length <= 0) {
        this.$root.warn("请输入标题");
        return;
      }
      if (
        this.deftimes(this.ruleForm.expireTime, this.ruleForm.startTime) < 0
      ) {
        this.$root.warn("修改失败，截至时间不能选在开始时间以前。");
        return;
      } else {
        this.$axios
          .put("/policy", this.ruleForm)
          .then((response) => {
            if (response.data.status === 200) {
              this.$root.success("编辑成功");
              this.current = 1;
              this.getDate();
              this.createDialog = false;
            } else {
              this.$root.warn("编辑失败", response.data.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    setCurrent(row) {
      this.$refs.multipleTable.clearSelection();
    },

    handleSelectionChange(val) {
      let self = this;
      if (val.length > 1) {
        self.setCurrent();
        this.status = 3;
        this.disabled = false;
        this.$root.warn("仅能单项勾选操作！");
        self.loading = true;
        setTimeout(function() {
          console.log(self.multipleSelection);
          self.loading = false;
        }, 1000);
      } else {
        this.multipleSelection = val;
        this.disabled = true;
        if (this.multipleSelection.length == 1) {
          this.status = this.multipleSelection[0].status;
          if (this.deftime(this.multipleSelection[0].expireTime) < 0) {
            if (this.status == 1) {
              this.disabled = true;
            } else {
              this.disabled = false;
            }

            //this.$root.warn("该政策已超过截止时间！");
          }
        } else {
          this.status = 3;
        }
      }
    },

    getDate() {
      var self = this;
      this.$axios
        .get("/policy/page", {
          params: {
            current: this.current,
            size: this.pageSize,
          },
        })
        .then(function(res) {
          if (res.data.status == 200) {
            self.tableData = res.data.data.records;
            self.total = res.data.data.total;
          } else if (res.data.status == 400) {
            if (self.current > 1) {
              --self.current;
              self.getDate();
            } else {
              self.tableData = [];
              self.total = 0;
            }
          } else {
            self.tableData = [];
            self.total = 0;
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    /**
     *
     * @param value
     */
    requestPage(value) {
      this.$router.replace({
        path: "/policylist",
        query: { page: value },
      });
      this.current = value;
      this.getDate();
      /* this.$axios.get('/policy/page', {
                      params: {
                          current: value,
                          size: this.pageSize,
                      }
                  }).then(response => {
                      this.tableData = response.data.data.records;
                      this.total = response.data.data.total
                  }) */
    },

    // // 编辑
    // aahandleEdit(row) {
    //     let self = this;
    //     this.createDialog = true;
    //     if(this.deftime(row.expireTime)<0){
    //         this.ruleForm.status=0
    //     }else{
    //         this.ruleForm.status=1
    //     }
    //
    //     this.$axios.get("/policy/?id=" + row.id).then(response => {
    //         if (response.data.status === 200) {
    //             this.ruleForm.title = response.data.data.title;
    //             this.ruleForm.content = response.data.data.clob.content;
    //             this.ruleForm.id = response.data.data.id;
    //             this.ruleForm.startTime = response.data.data.startTime;
    //             this.ruleForm.expireTime = response.data.data.expireTime;
    //             this.ruleForm.createTime = response.data.data.createTime;
    //         }
    //     })
    //
    // },

    //  点击编辑
    handleEdit(row) {
      if (this.ldbj) return;
      this.ldbj = true;
      setTimeout(() => {
        this.ldbj = false;
      }, 2000);
      let self = this;
      if (row.status !== 0) {
        self.$root.warn("已开启的必须关闭才能编辑！");
      } else {
        // self.$router.push({name: 'edit-declare',query: {id: row.id,page:this.page}})
        self.$router.replace({
          path: "/edit/declare",
          query: { id: row.id, page: this.current },
        });
      }
    },

    searchSubmit() {},
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .delete("/policy?id=" + row.id)
            .then((response) => {
              if (response.data.status === 200) {
                this.$root.success("删除成功");
                this.getDate();
              } else {
                this.$root.warn("删除失败");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    modifyForm(statusNum) {
      console.log(statusNum);
      let self = this;
      let thistrue = true;
      if (self.multipleSelection.length < 1) {
        this.$root.warn("请勾选择政策！至少选择一项");
        this.disabled = false;
        setTimeout(() => {
          this.disabled = true;
        }, 3000);
        return;
      }
      this.multipleSelection.forEach(function(item) {
        self.$axios
          .put("/policy", {
            id: item.id,
            status: statusNum,
          })
          .then(function(res) {
            if (res.data.status !== 200) {
              thistrue = false;
              self.$root.success("状态修改中断");
              return false;
            } else {
              self.$root.success("状态修改成功!");
              self.requestPage(self.current);
            }
          });
      });
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    deftime(endTime) {
      return this.$moment(endTime).diff(this.$moment(), "days");
    },
    deftimes(ETime, STime) {
      return this.$moment(ETime).diff(STime, "days");
    },
  },
};
</script>

<style lang="less" scoped>
#form {
  padding: 40px;
  color: #1091ed;
}
#pagination {
  margin: 32px 0 16px 0;
}
</style>
