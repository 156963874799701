<!-- 动态表单组件 -->
<template>
  <div class="edit">
    <el-row class="row">
      <el-button
        type="primary"
        class="Submit text"
        @click="Submitfrom"
        v-if="disabled"
        >提交</el-button
      >
      <el-button
        type="primary"
        class="Submit text"
        disabled
        :loading="true"
        v-else
        >提交</el-button
      >
      <el-col :span="18" style="text-align: left;">
        <div class="fromboxs">
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-button icon="el-icon-circle-plus-outline" size="mini"
                >单部件添加</el-button
              >
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>
                                <el-button size="mini" @click="setInput">单行输入框</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button size="mini" @click="setTextarea">多行输入框</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button size="mini" @click="setRadio">select单选</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button size="mini" @click="setCheckbox">check多选</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button size="mini" @click="setSelect">select下拉</el-button>
                            </el-dropdown-item> -->
              <el-dropdown-item>
                <el-button size="mini" @click="setUpload">一般文件</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  @click="setUnionUpload"
                  :disabled="Eindex >= 4"
                  >可拼接文件</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
            size="mini"
            @click="setInputTemplate()"
            :disabled="openIsDisabled"
            icon="el-icon-circle-plus-outline"
            style="margin: 0 15px;"
            >个人信息部件
          </el-button>

          <el-button
            icon="el-icon-circle-plus-outline"
            size="mini"
            style="margin: 0 15px;"
            @click="Dishonesty()"
            :disabled="openIsDishonesty"
            >失信信息查询结果</el-button
          >
          <el-button
            icon="el-icon-circle-plus-outline"
            size="mini"
            style="margin: 0 15px;"
            @click="Socialsecurity()"
            :disabled="openIsSocialsecurity"
            >开户许可证</el-button
          >

          <el-button
            icon="el-icon-circle-plus-outline"
            size="mini"
            style="margin: 0 15px;"
            @click="Servicedeclaration()"
            :disabled="openIsServicedeclaration"
            >服务事项申报表</el-button
          >
          <div class="hr-10"></div>
          <el-button
            icon="el-icon-circle-plus-outline"
            size="mini"
            @click="Recordregistration()"
            :disabled="openIsRecordregistration"
            >备案登记表
          </el-button>
          <el-button
            icon="el-icon-circle-plus-outline"
            size="mini"
            style="margin: 0 15px;"
            @click="Declarationattachment()"
            :disabled="openIsDeclarationattachment"
            >申报附件材料</el-button
          >
          <el-button
            icon="el-icon-circle-plus-outline"
            size="mini"
            style="margin: 0 15px;"
            @click="TelentTaxi()"
            :disabled="openIsTelentTaxi"
            >个人所得税</el-button
          >

          <div class="hr-20"></div>
          <!-- 表单渲染 -->
          <ul class="frombd">
            <li>
              <span>政策标题：</span>
              <el-input placeholder="请输入内容" v-model="title"></el-input>
            </li>
            <li>
              <span>可申报的时间范围：</span>
              <el-date-picker
                v-model="SeTime"
                type="datetimerange"
                align="right"
                :picker-options="expireTimeOption"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00']"
              >
              </el-date-picker>
              <i
                v-show="durations.length <= 4"
                class="el-icon-circle-plus-outline"
                style="margin-left: 12px"
                @click="requestAddTime"
              ></i>
            </li>
            <li v-if="addTime === true">
              <div class="hr-10"></div>
              <div v-for="(item, kye) in durations" :key="kye">
                <el-date-picker
                  v-model="item.SeTime"
                  type="datetimerange"
                  align="right"
                  :picker-options="expireTimeOption"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00']"
                >
                </el-date-picker>
                <i
                  class="el-icon-close"
                  style="margin-left: 12px"
                  @click="deleteOneTime(kye)"
                ></i>
                <div class="hr-10"></div>
              </div>
            </li>
            <li>
              <span>政策类型：</span>
              <!-- <el-input-number
                v-model="applyTimes"
                @change="setapplynum"
                :min="1"
                :max="10"
                label="申请次数"
              ></el-input-number> -->
              <el-select v-model="applyType" filterable placeholder="请选择">
                <el-option
                  v-for="item in applyTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </li>
            <li>
              <span>类型：</span>
              <el-radio v-model="type" label="talent">人才</el-radio>
              <el-radio v-model="type" label="enterprise">单位</el-radio>
            </li>
            <li>
              <!-- <div class="hr-10"></div>
              <span>pdf导出模板：</span>
              <el-upload
                class="upload-demo"
                :action="Host + '/file'"
                :before-upload="beforeAvatarUpload"
                :on-success="uploaddword"
                :on-error="onerror"
                :before-remove="beforeRemove"
                :headers="importHeaders"
                :limit="1"
                accept=".pdf"
                :on-exceed="handleExceed"
                :file-list="fileword"
              >
                <el-button size="small" type="primary" class="text"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip " style="color:red">
                  作为申报信息的导出模板，20mb以内
                </div>
              </el-upload> -->
              <div class="hr-10"></div>
              <span>附件：</span>
              <el-upload
                class="upload-demo"
                :action="Host + '/file'"
                :before-upload="beforeAvatarUpload"
                :on-success="uploadfile"
                :before-remove="beforeRemove"
                :on-error="onerror"
                :headers="importHeaders"
                :limit="10"
                accept=".doc,.docx,.xls,.ppt,.xlsx,.pptx,.zip,.pdf,.rar,.7z"
                :on-exceed="handleExceed"
                :file-list="fileid"
              >
                <el-button size="small" type="primary" class="text"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip " style="color:red">
                  可上传word、excel或压缩包文件(20mb内)
                </div>
              </el-upload>
            </li>
            <li>
              <span>政策内容：</span>
              <!--                            <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="content"></el-input>-->
              <editor ref="editor" />
            </li>
            <el-divider content-position="left">表单输入组件</el-divider>
            <li style="text-align: right">
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                v-if="butdelete"
                @click="ButBelete"
                >个人信息部件
              </el-button>
              <div class="hr-10"></div>
              <table
                width="750"
                height="682"
                border="0"
                cellspacing="1"
                bgcolor="#ddd"
                v-if="butdelete"
              >
                <tr>
                  <td width="90" height="52" align="center" bgcolor="#FFFFFF">
                    姓名
                  </td>
                  <td width="99" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td width="96" align="center" bgcolor="#FFFFFF">性别</td>
                  <td width="95" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td width="48" align="center" bgcolor="#FFFFFF">民族</td>
                  <td width="47" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td width="44" align="center" bgcolor="#FFFFFF">健康 状况</td>
                  <td width="102" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td width="101" rowspan="3" align="center" bgcolor="#FFFFFF">
                    照片
                  </td>
                </tr>
                <tr>
                  <td height="53" align="center" bgcolor="#FFFFFF">出生年月</td>
                  <td align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td align="center" bgcolor="#FFFFFF">政治 面貌</td>
                  <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td align="center" bgcolor="#FFFFFF">人才 类别</td>
                  <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                </tr>
                <tr>
                  <td height="54" align="center" bgcolor="#FFFFFF">
                    身份证号码(护照号码)
                  </td>
                  <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td colspan="2" align="center" bgcolor="#FFFFFF">
                    户籍所在地
                  </td>
                  <td colspan="3" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                </tr>
                <tr>
                  <td align="center" bgcolor="#FFFFFF">引进前单位 及职务</td>
                  <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td colspan="2" align="center" bgcolor="#FFFFFF">引进时间</td>
                  <td colspan="3" align="center" bgcolor="#FFFFFF">&nbsp;</td>
                  <td align="center" bgcolor="#FFFFFF">&nbsp;</td>
                </tr>
                <tr>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">
                    现工作单位 及职务
                  </td>
                  <td
                    colspan="4"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    &nbsp;
                  </td>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">
                    联系 方式
                  </td>
                  <td
                    colspan="3"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">
                    毕业学校 及专业
                  </td>
                  <td
                    colspan="4"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    &nbsp;
                  </td>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">
                    学历 学位
                  </td>
                  <td
                    colspan="3"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">
                    家庭住址
                  </td>
                  <td
                    colspan="8"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    rowspan="2"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    家庭 主要 社会 关系
                  </td>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">称谓</td>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">姓名</td>
                  <td
                    colspan="2"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    出生年月
                  </td>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">
                    政治 面貌
                  </td>
                  <td
                    colspan="3"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    现工作单位及职务
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">.</td>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">.</td>
                  <td
                    colspan="2"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    .
                  </td>
                  <td align="center" valign="middle" bgcolor="#FFFFFF">.</td>
                  <td
                    colspan="3"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    .
                  </td>
                </tr>
                <tr>
                  <td
                    height="251"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    个 人 简 历 及 所 获 荣 誉
                  </td>
                  <td
                    colspan="8"
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    &nbsp;
                  </td>
                </tr>
              </table>
            </li>
            <li
              v-for="(item, index) in formconfig"
              :key="index"
              v-on:mouseover="changeActive($event)"
              v-on:mouseout="removeActive($event)"
              :class="item.name"
            >
              <span>{{ item.label }}</span>
              <el-input
                v-if="item.type === 'text'"
                :type="item.type"
                placeholder="请输入内容"
                v-model="item.value"
              ></el-input>
              <el-input
                v-if="item.type === 'textarea'"
                :type="item.type"
                :rows="2"
                placeholder="请输入内容"
                v-model="item.textarea"
              ></el-input>
              <div class="radio" v-if="item.type === 'radio'">
                <el-radio
                  v-for="(items, index) in item.options"
                  :label="items.value"
                  name="exs"
                  v-model="item.value"
                  :key="index"
                  >{{ items.name }}
                </el-radio>
              </div>
              <div class="checkbox" v-if="item.type === 'checkbox'">
                <el-checkbox-group v-model="item.value">
                  <el-checkbox-button
                    v-for="(items, index) in item.options"
                    :label="items.value"
                    :key="index"
                    >{{ items.name }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </div>
              <div class="select" v-if="item.type === 'select'">
                <el-select v-model="item.value">
                  <el-option
                    v-for="items in item.options"
                    :key="items.value"
                    :label="items.name"
                    :value="items.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="upload" v-if="item.type === 'file'">
                <el-upload
                  class="upload-demo"
                  :action="Host + '/file'"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :headers="importHeaders"
                  multiple
                  :limit="1"
                  :on-exceed="handleExceed"
                  :file-list="fileList"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </div>
              <div v-if="item.type === 'date'">
                <el-date-picker
                  v-model="item.value"
                  :type="item.type"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
              <el-button
                size="mini"
                class="Edit"
                type="text"
                @click="Editele(index)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                class="Slice"
                type="text"
                @click="Deletes(index)"
                >删除</el-button
              >
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :span="6">
        <!-- 表单编辑工具 -->
        <el-dialog title="编辑" :visible.sync="dialogFormVisible">
          <el-card class="box-card fromtools" v-if="Index !== -1">
            <ul class="frombd" v-if="formconfig[Index]">
              <li v-if="formconfig[Index].type === 'text'">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span>label标题：</span>
                  </el-col>
                  <el-col :span="16">
                    <el-input
                      type="text"
                      v-model="formconfig[Index].label"
                    ></el-input>
                  </el-col>
                </el-row>
              </li>
              <li v-if="formconfig[Index].type === 'textarea'">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span>label标题：</span>
                  </el-col>
                  <el-col :span="16">
                    <el-input
                      type="text"
                      v-model="formconfig[Index].label"
                    ></el-input>
                  </el-col>
                </el-row>
              </li>
              <li v-if="formconfig[Index].type === 'file'">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span>label标题：</span>
                  </el-col>
                  <el-col :span="16">
                    <el-input
                      type="text"
                      v-model="formconfig[Index].label"
                    ></el-input>
                  </el-col>
                </el-row>
              </li>
              <li v-if="formconfig[Index].type === 'radio'">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span>label标题：</span>
                  </el-col>
                  <el-col :span="16">
                    <el-input
                      type="text"
                      v-model="formconfig[Index].label"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-divider content-position="left">选项a</el-divider>
                <ul>
                  <li
                    v-for="(item, index) in formconfig[Index].options"
                    :key="index"
                  >
                    <el-row :gutter="5">
                      <el-col :span="5">
                        <span>选项{{ index + 1 }}：</span>
                      </el-col>
                      <el-col :span="17">
                        <el-input
                          type="text"
                          v-model="item.name"
                          @input="handleInput(formconfig[Index].options[index])"
                        ></el-input>
                      </el-col>

                      <el-col :span="2">
                        <el-button
                          size="mini"
                          plain
                          icon="el-icon-close"
                          @click="del(index)"
                        ></el-button>
                      </el-col>
                    </el-row>
                  </li>
                  <el-button
                    size="mini"
                    class="Add"
                    plain
                    icon="el-icon-plus"
                    @click="Addlabel()"
                    >添加
                  </el-button>
                </ul>
              </li>
              <li v-if="formconfig[Index].type === 'checkbox'">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span>label标题：</span>
                  </el-col>
                  <el-col :span="16">
                    <el-input
                      type="text"
                      v-model="formconfig[Index].label"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-divider content-position="left">选项</el-divider>
                <ul>
                  <li
                    v-for="(item, index) in formconfig[Index].options"
                    :key="index"
                  >
                    <el-row :gutter="5">
                      <el-col :span="5">
                        <span>选项{{ index + 1 }}：</span>
                      </el-col>
                      <el-col :span="17">
                        <el-input
                          type="text"
                          v-model="item.name"
                          @input="handleInput(formconfig[Index].options[index])"
                        ></el-input>
                      </el-col>
                      <el-col :span="2">
                        <el-button
                          size="mini"
                          plain
                          icon="el-icon-close"
                          @click="del(index)"
                        ></el-button>
                      </el-col>
                    </el-row>
                  </li>
                  <el-button
                    size="mini"
                    class="Add"
                    plain
                    icon="el-icon-plus"
                    @click="Addlabel()"
                    >添加
                  </el-button>
                </ul>
              </li>

              <li v-if="formconfig[Index].type === 'select'">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span>label标题：</span>
                  </el-col>
                  <el-col :span="16">
                    <el-input
                      type="text"
                      v-model="formconfig[Index].label"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-divider content-position="left">选项</el-divider>
                <ul>
                  <li
                    v-for="(item, index) in formconfig[Index].options"
                    :key="index"
                  >
                    <el-row :gutter="5">
                      <el-col :span="5">
                        <span>选项{{ index + 1 }}：</span>
                      </el-col>
                      <el-col :span="17">
                        <el-input
                          type="text"
                          v-model="item.name"
                          @input="handleInput(formconfig[Index].options[index])"
                        ></el-input>
                      </el-col>
                      <el-col :span="2">
                        <el-button
                          size="mini"
                          plain
                          icon="el-icon-close"
                          @click="del(index)"
                        ></el-button>
                      </el-col>
                    </el-row>
                  </li>
                  <el-button
                    size="mini"
                    class="Add"
                    plainAddlabel
                    icon="el-icon-plus"
                    @click="Addlabel()"
                    >添加
                  </el-button>
                </ul>
              </li>
            </ul>
          </el-card>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import editor from "../../components/editor";

export default {
  name: "edit",
  components: {
    editor,
  },
  data() {
    return {
      importHeaders: { token: sessionStorage.getItem("Token") },
      SeTime: "",
      butdelete: false,
      addTime: false,
      addThreeTime: false,
      disabled: true,
      Host: this.$configDate.url,
      openIsDisabled: false,
      openIsDishonesty: false,
      openIsSocialsecurity: false,
      openIsServicedeclaration: false,
      openIsRecordregistration: false,
      openIsDeclarationattachment: false,
      openIsTelentTaxi: false,
      inputClassName: "",
      activeIndex: "1",
      dialogFormVisible: false,
      title: "",
      expireTime: "",
      startTime: "",
      durations: [],
      applyTimes: 0,
      applyType: 0, //政策类型 id
      applyTypeList: [], //政策类型列表
      type: "talent",
      content: "",
      templateId: 0,
      own: [],
      formconfig: [],
      fileword: [], //word模板
      fileid: [], //附件集
      fileids: [], //附件集
      fileList: [],
      Index: -1,
      EQindex: 0,
      Eindex: 0,
      Qindex: 0,
      expireTimeOption: {
        disabledDate(date) {
          // 当天可选：date.getTime() < Date.now() - 24 * 60 * 60 * 1000
          // 未来不可选 date.getTime() < Date.now() - 24 * 60 * 60 * 1000
          //return date.getTime() > Date.now() - 8.64e6
        },
      },
    };
  },
  created() {
    this.geTtypeData(1, 100);
  },
  methods: {
    handleSelect(key, keyPath) {},

    requestAddTime() {
      this.addTime = true;
      this.durations.push({
        SeTime: "",
        endTime: "",
        startTime: "",
        status: 0,
        type: "",
      });
    },

    deleteOneTime(kye) {
      this.durations.splice(kye, 1);
    },

    setInput() {
      this.EQindex++;
      this.formconfig.push({
        name: "talentText" + this.EQindex,
        label: "单行输入",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });
    },
    Dishonesty() {
      this.openIsDishonesty = !this.openIsDishonesty;
      this.formconfig.push({
        name: "dishonesty",
        label: "失信信息查询结果",
        type: "file",
      });
    },
    Socialsecurity() {
      this.openIsSocialsecurity = !this.openIsSocialsecurity;
      this.formconfig.push({
        name: "socialSecurity",
        label: "开户许可证",
        type: "file",
      });
    },
    Servicedeclaration() {
      this.openIsServicedeclaration = !this.openIsServicedeclaration;
      this.formconfig.push({
        name: "serviceDeclaration",
        label: "服务事项申报表",
        type: "file",
      });
    },
    Recordregistration() {
      this.openIsRecordregistration = !this.openIsRecordregistration;
      this.formconfig.push({
        name: "recordRegistration",
        label: "备案登记表",
        type: "file",
      });
    },
    Declarationattachment() {
      this.openIsDeclarationattachment = !this.openIsDeclarationattachment;
      this.formconfig.push({
        name: "declarationAttachment",
        label: "申报附件材料",
        type: "file",
      });
    },

    TelentTaxi() {
      this.openIsTelentTaxi = !this.openIsTelentTaxi;
      this.formconfig.push({
        name: "talentTaxi",
        label: "个人所得税",
        type: "file",
      });
    },

    setTextarea() {
      this.EQindex++;
      this.formconfig.push({
        name: "talentTextarea" + this.EQindex,
        label: "多行输入",
        type: "textarea",
        textarea: "",
      });
    },

    setRadio() {
      this.EQindex++;
      this.formconfig.push({
        name: "talentRadio" + this.EQindex,
        label: "单选",
        type: "radio",
        value: 0,
        required: true,
        placeholder: "",
        checked: false,
        options: [
          {
            name: "男",
            value: "男",
          },
          {
            name: "女",
            value: "女",
          },
        ],
      });
    },

    setCheckbox() {
      this.EQindex++;
      this.formconfig.push({
        name: "talentCheckbox" + this.EQindex,
        label: "多项选择",
        type: "checkbox",
        value: ["篮球"],
        required: true,
        placeholder: "",
        checked: false,
        options: [
          {
            name: "篮球",
            value: "篮球",
          },
          {
            name: "游戏",
            value: "游戏",
          },
          {
            name: "追剧",
            value: "追剧",
          },
          {
            name: "看书",
            value: "看书",
          },
        ],
      });
    },

    setSelect() {
      this.EQindex++;
      this.formconfig.push({
        name: "talentSelect" + this.EQindex,
        label: "下拉选项",
        type: "select",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
        options: [
          {
            value: "选项1",
            name: "选项1",
          },
        ],
      });
    },

    setUpload() {
      this.Qindex++;
      this.formconfig.push({
        name: "talentUpload" + this.Qindex,
        label: "一般文件上传",
        type: "file",
      });
    },
    setUnionUpload() {
      this.Eindex++;
      this.formconfig.push({
        name: "association_" + this.Eindex,
        label: "可拼接控件",
        type: "file",
      });
    },

    beforeAvatarUpload(file) {
      console.log(file);
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error("可上传word、excel或压缩包文件(20mb内)");
      }
      return isLt2M;
    },

    ButBelete() {
      let ownname = [];
      this.butdelete = false;
      this.openIsDisabled = !this.openIsDisabled;

      this.own.forEach((item) => {
        ownname.push(item.name);
      });

      const newArr = this.formconfig.filter(
        (item) => !ownname.includes(item.name)
      );
      this.formconfig = newArr;
    },

    //设置申请次数
    // setapplynum(value) {
    //   this.applyTimes = value;
    // },
    //获取政策类型列表
    geTtypeData(page, Size) {
      const self = this;
      this.$axios
        .get("/affairType/selectAll", {
          params: {
            pageNo: page,
            pageSize: Size,
          },
        })
        .then(function(res) {
          if (res.data.status === 200) {
            console.log(res.data.data.records);
            self.applyTypeList = res.data.data.records;
            self.applyType = res.data.data.records[0].id;
          }
        });
    },

    //个人信息模板
    setInputTemplate() {
      this.own = [];
      this.butdelete = true;
      this.openIsDisabled = !this.openIsDisabled;
      //姓名
      this.own.push({
        name: "name",
        label: "姓名",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //性别
      this.own.push({
        name: "sex",
        label: "性别",
        type: "radio",
        value: 0,
        required: true,
        placeholder: "",
        checked: false,
        options: [
          {
            name: "男",
            value: "男",
          },
          {
            name: "女",
            value: "女",
          },
        ],
      });

      //  民族
      this.own.push({
        name: "nation",
        label: "民族",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //  健康状况
      this.own.push({
        name: "healthy",
        label: "健康状况",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //出生日期
      this.own.push({
        name: "birthday",
        label: "出生日期",
        type: "date",
        value: 0,
      });

      //  政治面貌
      this.own.push({
        name: "politicalOutlook",
        label: "政治面貌",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //联系方式
      this.own.push({
        name: "phone",
        label: "联系方式",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //人才类型
      this.own.push({
        name: "type",
        label: "人才类型",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //身份证
      this.own.push({
        name: "identityCode",
        label: "居民身份证",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: true,
      });

      //  上一家工作单位及时间
      this.own.push({
        name: "lastEnterprise",
        label: "上一家工作单位",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      this.own.push({
        name: "lastHireDate",
        label: "上一家工作单位时间",
        type: "date",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //  现工作单位及职务
      this.own.push({
        name: "enterpriseName",
        label: "现工作单位及职务",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //学历学位
      this.own.push({
        name: "academic",
        label: "学历学位",
        type: "select",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
        options: [
          {
            value: "高中",
            name: "高中",
          },
          {
            value: "大专",
            name: "大专",
          },
          {
            value: "本科",
            name: "本科",
          },
          {
            value: "硕士研究生",
            name: "硕士研究生",
          },
          {
            value: "博士研究生",
            name: "博士研究生",
          },
        ],
      });

      //毕业学校
      this.own.push({
        name: "school",
        label: "毕业学校及专业",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //专业
      this.own.push({
        name: "major",
        label: "专业",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //  户籍所在地
      this.own.push({
        name: "registered",
        label: "户籍所在地",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });
      //  家庭住址
      this.own.push({
        name: "homeAddress",
        label: "家庭住址",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //  个人简历
      this.own.push({
        name: "resume",
        label: "个人简历",
        type: "textarea",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });
      this.own.push({
        name: "parentTitle",
        label: "称谓",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });
      this.own.push({
        name: "parentName",
        label: "家长姓名",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });
      this.own.push({
        name: "parentBirth",
        label: "家长出生年月",
        type: "date",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });
      this.own.push({
        name: "parentPolitical",
        label: "政治面貌",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });
      this.own.push({
        name: "parentJobtitle",
        label: "职务",
        type: "text",
        value: "",
        required: true,
        placeholder: "",
        checked: false,
      });

      //隐藏 附件上传控件
      this.formconfig.push({
        name: "talentType",
        label: "人才类型附件",
        type: "file",
      });
      this.formconfig.push({
        name: "talentPhoto",
        label: "免冠工作照",
        type: "file",
      });
      this.formconfig.push({
        name: "talentIDcardPositive",
        label: "身份证_正",
        type: "file",
      });
      this.formconfig.push({
        name: "talentIDcarDnegative",
        label: "身份证_反",
        type: "file",
      });
      this.formconfig.push({
        name: "talentSocial",
        label: "社保附件",
        type: "file",
      });
      this.formconfig.push({
        name: "talentEducation",
        label: "最高学历证书",
        type: "file",
      });
      this.formconfig.push({
        name: "talentFilingForm",
        label: "最高学历电子信息备案表",
        type: "file",
      });
      this.formconfig.push({
        name: "talentDegree",
        label: "最高学位证书",
        type: "file",
      });
      this.formconfig.push({
        name: "talentDeal",
        label: "劳动合同",
        type: "file",
      });

      this.formconfig.push({
        name: "businesslicense",
        label: "工商营业执照",
        type: "file",
      });

      this.formconfig = [...this.own, ...this.formconfig];
    },

    Editele(index) {
      //显示编辑面板
      this.Index = index;
      this.dialogFormVisible = true;
    },

    Deletes(index) {
      this.$confirm("是否删除该项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formconfig.splice(index, 1);
          this.Index = -1;
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          // this.$message({
          //     type: "info",
          //     message: "已取消删除"
          // });
        });
    },
    setDate(time) {
      console.log(time);
      console.log(this.SeTime[1]);
      let d = new Date(time);
      let datetime =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      console.log(datetime);
    },
    Submitfrom() {
      var self = this;
      this.durations.forEach((item, i) => {
        this.durations[i].startTime = item.SeTime[0];
        this.durations[i].endTime = item.SeTime[1];
      });
      let Data = {
        title: this.title,
        expireTime: this.SeTime[1],
        startTime: this.SeTime[0],
        durations: this.durations,
        applyTimes: this.applyType,
        content: this.$refs.editor.myValue,
        type: this.type,
        files: this.fileids,
        templateId: this.templateId,
        form: {
          enctype: "application/x-www-form-urlencoded",
          inputs: this.formconfig,
          method: "post",
          title: this.title,
        },
      };
      if (Data.title === "") {
        self.$notify({
          title: "失败",
          message: "您还没有填写政策标题！",
          type: "warning",
        });
        return;
      }
      if (Data.form.inputs.length === 0) {
        self.$notify({
          title: "失败",
          message: "您还没有添加表单组件！",
          type: "warning",
        });
        return;
      }

      Data.form.inputs.forEach(function(item) {
        item.placeholder = "请输入" + item.label;
      });

      this.$axios
        .post("/policy", Data)
        .then(function(res) {
          if (res.data.status === 200) {
            self.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
            });
            self.$emit("zifu", "子组件向父组件传值", true);
          } else {
            if (res.data.data.templateId) {
              self.sedisabled();
              self.$notify({
                title: "失败",
                message: "检查word模板",
                type: "warning",
              });
            } else if (res.data.data.startTime) {
              self.$notify({
                title: "失败",
                message: "需要是一个过去的时间",
                type: "warning",
              });
            } else if (res.data.data.expireTime) {
              self.$notify({
                title: "失败",
                message: "需要是一个将来的时间",
                type: "warning",
              });
            } else if (res.data.data.content) {
              self.$notify({
                title: "失败",
                message: "请输入政策内容",
                type: "warning",
              });
            } else {
              self.sedisabled();
              self.$notify({
                title: "失败",
                message: "创建政策失败",
                type: "warning",
              });
            }
          }
          //form.inputs: "请输入表单输入组件", content
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    sedisabled() {
      this.disabled = false;
      setTimeout(() => {
        this.disabled = true;
      }, 2000);
    },
    Addlabel() {
      this.formconfig[this.Index].options.push({
        value: "",
        name: "",
      });
    },

    del(id) {
      this.formconfig[this.Index].options.splice(id, 1);
    },

    handleInput(e) {
      e.value = e.name;
    },

    changeActive($event) {
      //console.log($event.currentTarget.className)
      this.inputClassName = $event.currentTarget.className;
      $event.currentTarget.className = this.inputClassName + " active";
    },

    removeActive($event) {
      $event.currentTarget.className = this.inputClassName;
    },

    handleRemove(file, fileList) {},

    handlePreview(file) {},

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },

    beforeRemove(file, fileList) {
      this.fileids.splice(
        this.fileids.findIndex((e) => e === file.response.data.id),
        1
      );
    },
    uploaddword(response, file, fileList) {
      if (response.status === 200) {
        this.templateId = response.data.id;
      } else {
        this.$notify({
          title: "失败",
          message: "文件上传失败",
          type: "warning",
        });
        this.fileword = [];
      }
    },
    onerror(err, file, fileList) {},
    uploadfile(response, file, fileList) {
      //uploadfile
      if (response.status === 200) {
        this.fileids.push(response.data.id);
      } else {
        this.$notify({
          title: "失败",
          message: "文件上传失败",
          type: "warning",
        });
        this.fileid = [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.row {
  height: 100%;
  background: #fff;

  .Submit {
    position: fixed;
    bottom: 100px;
    right: 100px;
    z-index: 111;
  }
}

.fromboxs {
  width: 800px;
  padding: 20px;
  min-height: 80vh;
}

.fromann {
  padding: 20px 0;

  button {
    margin: 0 5px;
  }
}

.frombd {
  padding: 0;
  margin: 0;

  span {
    display: block;
    text-align: left;
    padding: 10px 0;
  }

  li {
    list-style: none;
    position: relative;

    .radio,
    .checkbox,
    .select {
      text-align: left;
      border: 1px #ddd solid;
      padding: 10px 20px;
      border-radius: 5px;
    }

    .upload {
      text-align: left;
      padding: 8px 5px;
      background: #f5f5f5;
      border-radius: 6px;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    .Edit,
    .Slice {
      display: none;
    }
  }
  .name,
  .sex,
  .nation,
  .healthy,
  .birthday,
  .politicalOutlook,
  .phone,
  .type,
  .identityCode,
  .lastEnterprise,
  .lastHireDate,
  .enterpriseName,
  .homeAddress,
  .academic,
  .school,
  .major,
  .registered,
  .resume,
  .parentTitle,
  .parentName,
  .parentBirth,
  .parentPolitical,
  .parentJobtitle {
    display: none;
  }

  .active {
    .Edit {
      display: block;
      position: absolute;
      top: 40px;
      right: 9px;
    }

    .Slice {
      display: block;
      position: absolute;
      top: 58px;
      right: 9px;
    }
  }
}

.fromtools {
  padding: 20px;
  margin: 0 auto;
  background: #fff;
  width: 350px;

  .Add {
    width: 100%;
    margin: 8px 0;
  }
}

.time-style {
  width: 176px;
  margin: 8px 0;
}
.text {
  color: #ffffff;
}
</style>
